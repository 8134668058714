import { css } from '@emotion/react'
import type { StringsWithDataEnumDimension } from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import { DropdownFormField, ToggleButtonsFormField, typedMemo, useLanguage } from '@orus.eu/pharaoh'
import { useCallback, useMemo } from 'react'
import { requiredFieldMixin } from '../quote-editor-v2-util'

export type EnumDimensionFieldProps<
  NAME extends string,
  DATA extends { readonly label: string },
  ENTRIES extends ReadonlyArray<readonly [string, DATA]>,
  ValueDimension extends StringsWithDataEnumDimension<NAME, DATA, ENTRIES>,
> = {
  state: { readonly [key in NAME]?: ENTRIES[number][0] | null }
  valueDimension: ValueDimension
  setChanges?: (changes: { readonly [key in NAME]?: ENTRIES[number][0] | null }) => void
  variant: 'toggle-buttons' | 'dropdown'
  highlight?: boolean | null | undefined
}

export const EnumDimensionField = typedMemo(function EnumDimensionField<
  NAME extends string,
  DATA extends { readonly label: string },
  ENTRIES extends ReadonlyArray<readonly [string, DATA]>,
>(props: EnumDimensionFieldProps<NAME, DATA, ENTRIES, StringsWithDataEnumDimension<NAME, DATA, ENTRIES>>) {
  const language = useLanguage()
  const name: NAME = props.valueDimension.name
  const { valueDimension, setChanges } = props
  const values = useMemo(() => valueDimension.entries.map((entry) => entry[0]), [valueDimension])
  const labels = useMemo(
    () => Object.fromEntries(valueDimension.entries.map((entry) => [entry[0], entry[1].label])),
    [valueDimension],
  )
  const value = props.state[name] || null
  const handleChange = useCallback(
    (stringValue?: string | null) => {
      if (!setChanges) return

      const changes: { readonly [key in NAME]?: ENTRIES[number][0] | null } = {}

      if (!stringValue) {
        valueDimension.setUpdateFieldValue(changes, null)
        return
      }

      for (const entry of valueDimension.entries) {
        if (entry[0] === stringValue) {
          valueDimension.setUpdateFieldValue(changes, stringValue)
          setChanges(changes)
          return
        }
      }

      throw new TechnicalError('Unexpected value received from Dropdown component', { context: { stringValue } })
    },
    [valueDimension, setChanges],
  )

  const displayName = props.valueDimension.displayNames[language]

  const disabled = !setChanges
  return props.variant === 'dropdown' ? (
    <DropdownFormField
      fieldClassName={css`
        ${requiredFieldMixin(props.highlight, value)}
      `}
      size="small"
      label={displayName}
      value={value}
      placeholder={valueDimension.placeholders ? valueDimension.placeholders[language] : undefined}
      onChange={handleChange}
      values={values}
      labels={labels}
      disabled={disabled}
      infoTooltip={valueDimension.hints ? valueDimension.hints[language] : undefined}
    />
  ) : (
    <ToggleButtonsFormField
      fieldClassName={css`
        ${requiredFieldMixin(props.highlight, value)}
      `}
      size="small"
      label={displayName}
      value={value}
      onChange={handleChange}
      values={values}
      labels={labels}
      disabled={disabled}
    />
  )
})
