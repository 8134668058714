import type { StringsWithDataEnumDimension } from '@orus.eu/dimensions'
import { typedMemo } from '@orus.eu/pharaoh'
import { EnumDimensionField, type EnumDimensionFieldProps } from './enum-dimension-field'

export const DropdownDimensionField = typedMemo(function DropdownDimensionField<
  NAME extends string,
  DATA extends { readonly label: string },
  ENTRIES extends ReadonlyArray<readonly [string, DATA]>,
>(
  props: Omit<
    EnumDimensionFieldProps<NAME, DATA, ENTRIES, StringsWithDataEnumDimension<NAME, DATA, ENTRIES>>,
    'variant'
  >,
) {
  return <EnumDimensionField {...props} variant="dropdown" />
})
